export function getShareLink(
	plat,
	url = location.href,
	title = document.title,
	desc = document.title,
	pics = ""
) {
	const shareMaps = {
		qq: `//connect.qq.com/widget/shareqq/index.html?url=${encodeURIComponent(
			url
		)}&desc=${desc}&title=${title}`,
		qz: `//sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(
			url
		)}&sharesource=qzone&title=${title}&desc=${desc}`,
		wb: `//service.weibo.com/share/share.php?url=${encodeURIComponent(
			url
		)}&sharesource=weibo&title=${title}&pic=${encodeURIComponent(pics)}`,
		tx: `//share.v.t.qq.com/index.php?c=share&a=index&title=${title}&url=${encodeURIComponent(
			url
		)}`,
		more: `//shuo.douban.com/!service/share?href=${encodeURIComponent(
			url
		)}&name=${title}&text=${desc}&image=${pics}`
	};
	return shareMaps[plat] || shareMaps.more;
}
