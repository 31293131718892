import * as React from "react";
import "./index.less";
let url = require("src/assets/Index/banner.jpg");
let vdo = "http://yxfile.3k.com/2020/01/10/upload_q0dyxeadne6koirhv06g3nbp4zxtlhn1.mp4";
export default (function() {
	let res = null;
	case$_devAndServer: {
		res = () => (
			<div
				className="banner"
				data-common="视频链接放data-vidoe-url属性里，预览图放data-poster-url"

				data-video-url={vdo}
				data-poster-url={url}
			>
				{/* <div
					data-style-comment="头图背景图片直接在style里面换"
					style={{
						backgroundImage: `url(${url})`
					}}
				/> */}
				<i />
			</div>
		);
	}
	case$_client: {
		$(function() {
			let url = $(".banner").attr("data-video-url");
			let url2 = $(".banner").attr("data-poster-url");
			if (!url) {
				return;
			}
			let vdo = document.createElement("video");
			vdo.poster=url2;
			vdo.muted = true;
			vdo.loop = true;
			vdo.autoplay = true;
			vdo.controls = false;
			vdo.style.objectFit = "cover";
			vdo.style.opacity = 0;
			vdo.oncanplay = () => {
				vdo.width = $(".banner").width();
				vdo.height = $(".banner").height();
				vdo.style.opacity = 1;
			};
			$(".banner").append($(vdo));
			vdo.src = url;
		});
	}

	return res;
})();
