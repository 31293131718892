import * as React from "react";
import $ from 'jquery'
import createEntry from "src/utils/common/createEntry";
import Header from "src/component/Header/index.js";
import Footer from "src/component/Footer/index.js";
import Banner from "./Banner";
import News from "./News";
import Feature from "./Feature";
import "./index.less";
export default createEntry((function() {
	let res = null;
	case$_devAndServer: {
		class PageIndex extends React.Component {
			render() {
				return (
					<>
						<Header></Header>
						<Banner />
						<News />
						<Feature />
						<Footer />
						<div className="float-right">
							<img role="下载二维码" src=""/>
							<a data-name="领取礼包" style={{top:138}} href=""></a>
							<a data-name="iOS下载" style={{top:372}} href=""></a>
							<a data-name="安卓下载" style={{top:436}} href=""></a>
							<div className="toggle open"></div>
						</div>
					</>
				);
			}
		}
        res = PageIndex
	}
	case$_client:{
		$(document.body).on("click",".float-right .toggle",function(){
			if($(this).hasClass('open')){
				$(this).removeClass('open');
				$(".float-right").addClass("hide");
				return
			}
			$(".float-right").removeClass("hide");
			$(this).addClass("open");

		})
	}
    
    return res
})());

