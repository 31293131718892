import * as React from "react";
import styles from "./index.module.less";
const NAV_ITEM = styles["nav-item"];

export default (function() {
	let res = null;
	case$_devAndServer: {
		let nav = [
			{
				cn: "官网首页",
				en: "HOME",
				href: "/"
			},
			{
				cn: "游戏资料",
				en: "GAME DATA",
				href: "/"
			},
			{
				cn: "游戏礼包",
				en: "GAME PACK",
				href: "/"
			},
			{
				cn: "开服表",
				en: "SERVICE TABLE",
				href: "/"
			},
			{
				cn: "客服中心",
				en: "SUPPORT",
				href: "/"
			},
			{
				cn: "游戏社区",
				en: "COMMUNITY",
				href: "/"
			}
		];
		res = ()=>(
			<header className={styles.header}>
				<nav>
					{nav.map((item, i) => (
						<a
							href={item.href}
							key={item.en}
							className={[NAV_ITEM, i === 0 ? "atv" : ""].join(
								" "
							)}
						>
							<span>{item.cn}</span>
							<small>{item.en}</small>
						</a>
					))}
				</nav>
			</header>
		);
	}
	return res;
})();
