import * as React from 'react';
import "src/styles/common.less"
export default function(App){
    case$_client:{
        require('@babel/polyfill')
    }
    
    
    function main(){
        let res = ()=>null;
        case$_dev:{
            let ReactDOM = require('react-dom')
            let app = document.getElementById("app");
            ReactDOM.render(<App/>,app);
        }
    
        case$_server:{
            res=()=><App/>
        }
        return res
    }

    return main();
}