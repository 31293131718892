import $ from "jquery";
import "./index.less";
export default (function() {
	let res = null;
	require('src/assets/Index/news-lt.jpg')
	case$_devAndServer: {
		res = require("./index.jsx").default;
	}
	case$_client: {
		$(document.body).on("click", ".news .tabs li", function(e) {
			let index = $(this).index();
			$(".news .tabs .atv").removeClass("atv");
			$(this).addClass("atv");
			$(".news .tab-warp").css({
				marginLeft: -560 * index
			});
			$(".news .tab-item").removeClass("atv");
			$(".news .tab-item")
				.eq(index)
				.addClass("atv");
		});

		$(function(){
			new window.Swiper(".news .swiper",{
				pagination:".news .pagination",
				loop:true,
				autoplay:3000
			})
		});
	}

	return res;
})();
