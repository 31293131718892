import * as React from "react";
import "./index.less";
export default (function() {
	let res = null;
	let url = require("src/assets/Index/feature-swiper.png")
	case$_devAndServer: {
		res = () => (
			<div className="feature">
				<div className="tt"></div>
				<div className="swiper swiper-container">
					<div className="swiper-wrapper">
						{new Array(5).fill(1).map((item, i) => (
							<div key={i} className="swiper-slide">
								<img
									src={url}
								/>
							</div>
						))}
					</div>
				</div>
				<div className="btn btn-prev"></div>
				<div className="btn btn-next"></div>
				<div className="pagination"></div>
			</div>
		);
	}
	case$_client: {
		
		$(function(){
			let myswp = new window.Swiper('.feature .swiper',{
				pagination:".feature .pagination",
				loop:true,
				loopAdditionalSlides:3,
				slidesPerView : 'auto',
				loopedSlides:3,
				centeredSlides : true,
				tdFlow: {
					rotate : 30,
				},
				initialSlide:2,
				onInit(e){
					myswp = e;
					myswp.swipeTo(0);
				}
			});
			$(document.body).on("click",".feature .btn",function(){
				if($(this).hasClass('btn-prev')){
					myswp.swipePrev();
					return
				}
				myswp.swipeNext();
			})
		})
	}

	return res;
})();
