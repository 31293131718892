import $ from "jquery";
import {getShareLink} from "src/utils/common/share";
import styles from "./index.module.less";
let res = null;
console.log(getShareLink)
case$_devAndServer: {
	res = require("./index.jsx").default;
}

case$_client: {
	require("src/assets/Footer/qrcode-download.png");
	require("src/assets/Footer/qrcode-wx.png");
	require("src/assets/Footer/qrcode-gm.png");
	console.log(getShareLink)
	$(document.body).on("click", "#footerShare a", function() {
		let $this = $(this);
		let title = $this.attr("data-title");
		let desc = $this.attr("data-desc");
		let type = $this.attr("data-type");

		if (!type) {
			return;
		}

		if (type === "wx") {
			$(`.${styles["wx-share-dialog"]}`).show();
			return;
		}
		console.log(getShareLink)
		window.open(
			getShareLink(
				type,
				location.href,
				title || document.title,
				desc || document.title
			)
		);
	});

	$(document.body).on(
		"click",
		`.${styles["wx-share-dialog"]} button`,
		function() {
			$(`.${styles["wx-share-dialog"]}`).hide();
		}
	);
}
export default res;
